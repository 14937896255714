<template>
  <div class="app-contianer">
    <div class="page-bg">
      <div class="bg"></div>
    </div>

    <div id="app-content">
      <div class="img-box">
        <img src="../../../assets/user/service.png">
      </div>
      <p class="title">疑惑解答，代理加盟，合作商家联系</p>
      <div class="info-box">
        <p>工作时间：{{agentConfig.SERVICE_TIME}}</p>
        <p>手机号码: {{agentConfig.SERVICE_TELE ? agentConfig.SERVICE_TELE:'--'}}</p>
        <p>微信号: {{agentConfig.SERVICE_WECHAT ? agentConfig.SERVICE_WECHAT:'--'}}</p>
      </div>
      <div class="online-number page-item">
        <div class="item wechat" @click="gotocopy(agentConfig.SERVICE_WECHAT)">
          <img src="../../../assets/user/wx.png" />
          <div>微信联系</div>
        </div>
        <div class="item phone" @click="gotocopy(agentConfig.SERVICE_TELE)">
          <img src="../../../assets/user/phone.png" />
          <div>电话联系</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getToken } from '../../../api'
import { mapState } from 'vuex'


export default {
  name: 'contactus',
  data() {
    return {}
  },
  computed: {
    ...mapState('user', ['agentConfig']),
  },
  created() { },
  methods: {
    // 复制
    gotocopy(val) {
      if (!val) {
        this.$toast('平台未设置')
        return
      }
      this.$copyText(val).then((res) => {
        this.$toast.success('复制成功')
      })
    },
  },
}
</script>

<style lang="less" scoped>
.app-contianer {
  padding-bottom: 0;

  .img-box {
    margin-top: 20vw;
    padding: 0 10vw;
    text-align: center;

    img {
      width: 70%;
    }
  }

  .title {
    text-align: center;
    font-weight: bold;
    font-size: 4vw;
    margin: 4vw;
  }
  .info-box {
    text-align: center;
    color: #999;

    p {
      margin-bottom: 1vw;
    }
  }

  .online-number {
    display: flex;
    justify-content: space-around;
    margin-top: 10vw;

    .item {
      text-align: center;
      font-size: 3.6vw;
      padding: 1vw 2vw;
      img {
        width: 12vw;
      }
    }
  }
}
</style>
